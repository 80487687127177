export default function () {
    $('.single-job .image-text__cta .button').on('click', function (event) {
        event.preventDefault();

        $('html,body').animate({ scrollTop: $('.single-job__contact').offset().top }, 500);

        setTimeout(() => {
            $('.js-trigger-job-form').addClass('hidden');
            $('.js-single-job-form').slideDown(300);
        }, 1000);
    });

    $('.single-job .js-trigger-job-form').on('click', function (event) {
        event.preventDefault();

        $(this).addClass('hidden');
        $('.single-job .js-single-job-form').slideDown(300);
    });
}

export default function () {
    loadMore();
    search();
}

function search() {
    const searchInput = document.querySelector('.archive-news__search input');
    let searchTimeout;

    if(!searchInput) return;
    searchInput.addEventListener('input', () => {
        clearTimeout(searchTimeout);
        const value = searchInput.value.trim();

        if (value.length >= 3) {
            searchTimeout = setTimeout(() => {
                getPostsAjax([], value);
            }, 1000);
        }

        if (value.length === 0) {
            getPostsAjax([], ' ');
        }
    });
}

function loadMore() {
    if(!$('.archive-news__cta').length) return;
    $('.archive-news__cta').on('click', function (e) {
        e.preventDefault();
        const posts = $('.archive-news__container .news-card');
        let postIds = new Array();

        posts.each(function (index, post) {
            postIds.push($(post).data('post'));
        });

        getPostsAjax(postIds);
    });
}

function getPostsAjax(offset = [], search = '') {
    const container = $('.archive-news__container');
    let data = new Object;

    if (offset.length !== 0) {
        data['offset'] = offset;
    }

    if (search) {
        data['s'] = search;
    }

    $.ajax({
        type    : 'GET',
        url     : menaObject.ajaxurl,
        dataType: 'html', // add data type
        data    : {
            action: 'menaGetNewsAjax',
            data  : data,
        },

        beforeSend: function () {
            $(container).addClass('loading');
        },

        success: function (response) {
            if (response) {
                response = JSON.parse(response);

                if (response.html) {
                    if (offset.length !== 0) {
                        $(container).append(response.html);
                    } else {
                        $(container).html(response.html);
                    }
                }

                if (response.maxNumOfPosts) {
                    let posts = $('.archive-news__container .news-card');
                    let loadMoreBtn = $('.archive-news__cta');

                    if (response.maxNumOfPosts <= posts.length) {
                        $(loadMoreBtn).slideUp(300);
                    } else {
                        $(loadMoreBtn).slideDown(300);
                    }
                }

                $(container).removeClass('loading');
            }
        },
    });
}

export default function () {
    const swiper = new Swiper('.js-testimonial-slider', {
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        autoHeight: false,
        speed: 1500,
        grabCursor: true,
        autoplay: {
            delay: 5000,
        },
        breakpoints: {
            1024: {
                spaceBetween: 24,
                slidesPerView: 2,
                initialSlide: 1,
            },
        },
    });
}

export default function () {
    const swiper = new Swiper('.js-logo-slider', {
        slidesPerView: 2,
        centeredSlides: true,
        spaceBetween: 40,
        grabCursor: true,
        loop: true,
        speed: 1500,
        disableOnInteraction: false,
        autoplay: {
            delay: 5000,
        },
        breakpoints: {
            420: {
                slidesPerView: 2,
            },
            640: {
                spaceBetween: 40,
                slidesPerView: 3,
            },
            1024: {
                spaceBetween: 80,
                slidesPerView: 5,
            },
        },
    });
}

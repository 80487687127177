export default function () {
    var storeLocatorAtrack = analyticstracker();
    var bodyShopSearchDone = false;
    var mapStyles = [
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers    : [
                {
                    color: '#444444',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers    : [
                {
                    color: '#f2f2f2',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers    : [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'geometry.fill',
            stylers    : [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers    : [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'all',
            stylers    : [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers    : [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'all',
            stylers    : [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers    : [
                {
                    color: '#b4d4e1',
                },
                {
                    visibility: 'on',
                },
            ],
        },
    ];

    if ($('.store-locator').length > 0) {
        let calculateDistance = false;

        function recalculateLocationsHeight() {
            // Since on mobile it's hidden we need to briefly show it to get exact dimensions
            if (window.innerWidth < 1024) {
                $('.store-locator__locations').show();
            }
            let item1Height = $('.store-locator__item:nth-child(1').outerHeight();
            let item2Height = $('.store-locator__item:nth-child(2').outerHeight();
            let item3Height = $('.store-locator__item:nth-child(3').outerHeight();
            if (window.innerWidth < 1024) {
                $('.store-locator__locations').hide();
            } else {
                $('.store-locator__locations').show();
            }

            let listHeight = item1Height + item2Height + item3Height + 28 + 'px';
            $('.store-locator__locations-wrap').css('height', listHeight);
        }

        window.addEventListener('load', recalculateLocationsHeight);

        var rad = function (x) {
            return (x * Math.PI) / 180;
        };

        var getDistance = function (p1, p2) {
            var R = 6378137; // Earth’s mean radius in meter
            var dLat = rad(p2.lat - p1.lat);
            var dLong = rad(p2.lng - p1.lng);
            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return d / 1000; // returns the distance in km
        };

        function prepareData(placeLocation) {
            let locationsData = [];
            let position,
                shopLat,
                shopLng,
                shopId;

            $('.store-locator__item:not(.hide)').each(function () {
                let $this = $(this);

                if ($this.data('lat') && $this.data('lng')) {
                    position = {
                        lat: $this.data('lat'),
                        lng: $this.data('lng'),
                    };
                    shopId = $this.attr('id');
                    let is_open = true;

                    let isComingSoon = false;
                    if ($this.find('.store-locator__item-status').length > 0) {
                        //if $this hasClass coming-soon
                        if ($this.hasClass('store-locator__item--soon')) {
							isComingSoon = true;
						}
                    }

                    locationsData.push({
                        position,
                        is_open,
                        shopId,
                        isComingSoon,
                    });
                }
            });

            // Check if data-lang and data-lat exists on .store-locator__map-el block and use it as center
            if ($('.store-locator__map-el').data('lat') && $('.store-locator__map-el').data('lang')) {
                placeLocation = {
                    lat: $('.store-locator__map-el').data('lat'),
                    lng: $('.store-locator__map-el').data('lang'),
                };

                calculateDistance = true;
            }

            initMap(locationsData, placeLocation, calculateDistance);
        }

        function initMap(locationsData, placeLocation) {
            function handleLocationError(err) {
                console.error(`ERROR(${err.code}): ${err.message}`);
            }

            loadjs(['https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js'], function () {
                //var markers = [];
                var bounds = new google.maps.LatLngBounds();
                let language = $('html').attr('lang');
                let languageShort = language.substring(0, 2);
                var map = new google.maps.Map(document.querySelector('.store-locator__map-el'), {
                    zoom            : 4,
                    center          : placeLocation,
                    styles          : mapStyles,
                    disableDefaultUI: true,
                    zoomControl     : true,
                    language        : languageShort,
                });


                const markers = locationsData.map((location, i) => {
                    bounds.extend(location.position);
                    let icon = menaObject.pin;

                    if(location.isComingSoon) {
                        icon = menaObject.pinGold;
                    }

                    return new google.maps.Marker({
                        position: location.position,
                        icon    : icon,
                        shopId  : location.shopId,
                        isComingSoon: location.isComingSoon,
                    });
                });

                if (placeLocation.lat > 0 && placeLocation.lng > 0) {
                    bounds.extend(placeLocation);
                    const marker = new google.maps.Marker({
                        map     : map,
                        icon    : menaObject.pinCenter,
                        position: placeLocation,
                    });
                }

                var markerCluster = new MarkerClusterer(map, markers, {
                    imagePath: menaObject.cluster,
                    styles   : [
                        {
                            textColor : '#fff',
                            url       : menaObject.cluster,
                            width     : 50,
                            height    : 50,
                            textSize  : 14,
                            anchorText: [15.5, 0],
                        },
                    ],
                });

                if (calculateDistance) {
                    let activeStores = [];
                    let hasStoreInFirstRadius = false;
                    $('.store-locator__item').each(function () {
                        let $this = $(this);
                        let storeDistance = Math.round(
                            getDistance(placeLocation, {
                                lat: parseFloat($this.data('lat')),
                                lng: parseFloat($this.data('lng')),
                            }),
                        );
                        $this.removeClass('hide');
                        let hasStoreInFirstRadius = true;
                        let storeData = {
                            id      : $this.attr('id'),
                            distance: storeDistance,
                        };
                        activeStores.push(storeData);
                    });

                    if (!hasStoreInFirstRadius) {
                        $('.store-locator__item').each(function () {
                            let $this = $(this);
                            let storeDistance = Math.round(
                                getDistance(placeLocation, {
                                    lat: parseFloat($this.data('lat')),
                                    lng: parseFloat($this.data('lng')),
                                }),
                            );
                            $this.removeClass('hide');
                            hasStoreInFirstRadius = true;
                            let storeData = {
                                id      : $this.attr('id'),
                                distance: storeDistance,
                            };
                            activeStores.push(storeData);
                        });
                    }

                    if (activeStores.length > 0) {
                        activeStores.sort(function (a, b) {
                            return a.distance - b.distance;
                        });

                        activeStores.forEach((item) => {
                            $('#' + item.id).css('order', item.distance);
                        });
                    } else {
                        console.log('No stores found in the area');
                    }
                }

                var markerFunc = markers.map((marker) => {
                    google.maps.event.addListener(marker, 'mouseover', function () {
                        marker.setIcon(menaObject.pinDark);
                    });
                    google.maps.event.addListener(marker, 'mouseout', function () {
                        if(marker.isComingSoon) {
                            marker.setIcon(menaObject.pinGold);
                        } else {
                            marker.setIcon(menaObject.pin);
                        }
                    });

                    google.maps.event.addListener(marker, 'click', function () {
                        let shopId = marker.get('shopId');
                        let url;

                        let innerWidth = window.innerWidth;

                        let element = $('#' + shopId);

                        // if element is a get href
                        if(element.length !== 0 && element.attr('href')) {
                            url = $('#' + shopId).attr('href');
                        } else {
                            url = false;
                        }

                        var elementTop = document.querySelector('.store-locator__locations-wrap').offsetTop;
                        var divTop = document.querySelector('#' + shopId).offsetTop;
                        var elementRelativeTop = divTop - elementTop;

                        $('.store-locator__item.active').removeClass('active');
                        $('#' + shopId).addClass('active');

                        if (innerWidth < 1024 && url) {
                            window.location.href = url;
                        }

                        document.querySelector('.store-locator__locations-wrap').scroll({
                            top     : elementRelativeTop,
                            left    : 0,
                            behavior: 'smooth',
                        });
                    });
                });

                let infoWindow = new google.maps.InfoWindow();
                const locationButton = document.querySelector('.store-locator--block .js-locate-me');

                const options = {
                    componentRestrictions: { country: 'be' },
                    fields               : ['address_components', 'geometry'],
                };

                if ($('.store-locator__search input').length > 0) {
                    let searchBoxInput = document.querySelector('.store-locator__search input');
                    const searchBox = new google.maps.places.Autocomplete(searchBoxInput, options);

                    var selectFirstOnEnter = function (input) {
                        // store the original event binding function
                        var _addEventListener = input.addEventListener ? input.addEventListener : input.attachEvent;

                        function addEventListenerWrapper(type, listener) {
                            // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected, and then trigger the original listener.
                            if (type == 'keydown') {
                                var orig_listener = listener;
                                listener = function (event) {
                                    var suggestion_selected = $('.pac-item-selected').length > 0;
                                    if (event.which == 13 && !suggestion_selected) {
                                        var simulated_downarrow = $.Event('keydown', {
                                            keyCode: 40,
                                            which  : 40,
                                        });
                                        orig_listener.apply(input, [simulated_downarrow]);
                                    }
                                    orig_listener.apply(input, [event]);
                                };
                            }
                            _addEventListener.apply(input, [type, listener]); // add the modified listener
                        }

                        if (input.addEventListener) {
                            input.addEventListener = addEventListenerWrapper;
                        } else if (input.attachEvent) {
                            input.attachEvent = addEventListenerWrapper;
                        }
                    };

                    selectFirstOnEnter(searchBoxInput);

                    map.addListener('bounds_changed', () => {
                        searchBox.setBounds(map.getBounds());
                    });

                    google.maps.event.addDomListener(searchBox, 'keydown', function (event) {
                        if (event.keyCode === 13) {
                            event.preventDefault();
                        }
                    });

                    searchBox.addListener('place_changed', () => {
                        const place = searchBox.getPlace();
                        let activeStores = [];
                        let storeData;
                        let hasStoreInFirstRadius = false;
                        if (place.length == 0) {
                            return;
                        }

                        if (!place.geometry || !place.geometry.location) {
                            return;
                        }

                        let markersInAreaCount = 0;

                        let placeLocation = {
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng(),
                        };

                        $('.store-locator__item').each(function () {
                            let $this = $(this);
                            let storeDistance = Math.round(
                                getDistance(placeLocation, {
                                    lat: parseFloat($this.data('lat')),
                                    lng: parseFloat($this.data('lng')),
                                }),
                            );
                            //shopId = $this.attr('id');
                            if (storeDistance > menaObject.zipCodeDistance) {
                                $this.addClass('hide');
                            } else {
                                $this.removeClass('hide');
                                markersInAreaCount++;
                                hasStoreInFirstRadius = true;
                                // Add storeDistance to data attribute data-distance
                                $this.attr('data-distance', storeDistance);

                                let storeData = {
                                    id      : $this.attr('id'),
                                    distance: storeDistance,
                                };

                                activeStores.push(storeData);
                            }
                        });

                        if (!hasStoreInFirstRadius) {
                            $('.store-locator__item').each(function () {
                                let $this = $(this);
                                let storeDistance = Math.round(
                                    getDistance(placeLocation, {
                                        lat: parseFloat($this.data('lat')),
                                        lng: parseFloat($this.data('lng')),
                                    }),
                                );
                                //shopId = $this.attr('id');
                                if (storeDistance > menaObject.zipCodeDistanceAlternate) {
                                    $this.addClass('hide');
                                } else {
                                    $this.removeClass('hide');
                                    markersInAreaCount++;
                                    hasStoreInFirstRadius = true;

                                    let storeData = {
                                        id      : $this.attr('id'),
                                        distance: storeDistance,
                                    };

                                    activeStores.push(storeData);
                                }
                            });
                        }

                        if (activeStores.length > 0) {

                            activeStores.sort(function (a, b) {
                                return a.distance - b.distance;
                            });

                            activeStores.forEach((item, index) => {
                                $('#' + item.id).css('order', item.distance).attr('data-position', index + 1);
                            });
                        } else {
                            console.warn('No stores found in the area');
                        }

                        prepareData(placeLocation);
                        let placeAddress = place.address_components[0].long_name + ' ' + place.address_components[1].long_name;
                        let label = $('.store-locator__label').data('closeProvince');
                        let labelTitle = $('.store-locator__title').data('provinceTitle');
                        $('.store-locator__label').html(`<strong>${markersInAreaCount}</strong> ${label} ${placeAddress}`);
                        $('.store-locator__title').html(`${labelTitle} <strong> ${placeAddress} </strong>`);

                        /**
                         * Body shop search tracking
                         */
                        let bodyShopCount = $('.store-locator__locations .store-locator__item:not(.hide)').length;
                        let bodyShopSearchQuery = $('.store-locator__search input[type="text"]').prop('value');

                        let trackingEvent = {
                            event: 'search-query',
                            info : {
                                searchType          : 'filter',
                                searchKeyword       : String(bodyShopSearchQuery),
                                searchResult        : '',
                                searchResultPosition: '',
                                searchResultCount   : String(bodyShopCount),
                            },
                        };

                        storeLocatorAtrack.trackEvent(trackingEvent);
                        bodyShopSearchDone = true;
                    });
                }
                if ($('.store-locator--block .js-locate-me').length > 0) {
                    function locateMe(position) {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };

                        let activeStores = [];
                        let storeData;

                        let markersInAreaCount = 0;

                        $('.store-locator__item').each(function () {
                            let $this = $(this);
                            let storeDistance = getDistance(pos, {
                                lat: parseFloat($this.data('lat')),
                                lng: parseFloat($this.data('lng')),
                            });
                            let storeDistanceRounded = Math.round(storeDistance);
                            if (storeDistance <= menaObject.locateMeDistance) {
                                $this.removeClass('hide');
                                markersInAreaCount++;

                                let storeData = {
                                    id      : $this.attr('id'),
                                    distance: storeDistanceRounded,
                                };

                                activeStores.push(storeData);
                            } else {
                                $this.addClass('hide');
                            }
                        });

                        if (markersInAreaCount == 0) {
                            alert(menaObject.noResultsLabel);
                        } else {
                            prepareData(pos);
                            let locationTitle = $('.store-locator__title').data('locationTitle');
                            $('.store-locator__title').html(locationTitle);
                            activeStores.sort(function (a, b) {
                                return a.distance - b.distance;
                            });

                            if (activeStores.length > 0) {
                                activeStores.forEach((item) => {
                                    $('#' + item.id).css('order', item.distance);
                                });
                            }
                        }
                        let label = $('.store-locator__label').data('closeLocation');
                        $('.store-locator__label').html(`<strong>${markersInAreaCount}</strong> ${label} `);
                    }

                    locationButton.addEventListener('click', () => {
                        // Try HTML5 geolocation.
                        if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition(locateMe, handleLocationError);
                        } else {
                            // Browser doesn't support Geolocation
                            handleLocationError(false, infoWindow, map.getCenter());
                        }
                    });
                }

                map.fitBounds(bounds);

                // Controll max zoom in case there's a single marker
                var initial = true;
                google.maps.event.addListener(map, 'zoom_changed', function () {
                    if (initial) {
                        if (map.getZoom() > 11) {
                            map.setZoom(11);
                            initial = false;
                        }
                    }
                });
            });
        }

        // So we can check whether user came from prefooter form or directly
        if (localStorage.getItem('zipLocation')) {
            let place = JSON.parse(localStorage.getItem('zipLocation'));
            let placeLocation,
                markersInAreaCount;
            if (!place.geometry) {
                placeLocation = {
                    lat: 0,
                    lng: 0,
                };
                localStorage.removeItem('zipLocation');
            } else {
                placeLocation = {
                    lat: place.geometry.location.lat,
                    lng: place.geometry.location.lng,
                };
                let placeAddress = place.address_components[0].long_name + ' ' + place.address_components[1].long_name;
                let label = $('.store-locator__label').data('closeProvince');

                let labelTitle = $('.store-locator__title').data('provinceTitle');

                $('.store-locator__title').html(`${labelTitle} <strong> ${placeAddress} </strong>`);

                markersInAreaCount = 0;
                let activeStores = [];
                let storeData;
                let hasStoreInFirstRadius;

                $('.store-locator__item').each(function () {
                    let $this = $(this);
                    let storeDistance = Math.round(
                        getDistance(placeLocation, {
                            lat: parseFloat($this.data('lat')),
                            lng: parseFloat($this.data('lng')),
                        }),
                    );
                    if (storeDistance > menaObject.zipCodeDistance) {
                        $this.addClass('hide');
                    } else {
                        $this.removeClass('hide');
                        markersInAreaCount++;
                        hasStoreInFirstRadius = true;
                        let storeData = {
                            id      : $this.attr('id'),
                            distance: storeDistance,
                        };

                        activeStores.push(storeData);
                    }
                });

                if (!hasStoreInFirstRadius) {
                    $('.store-locator__item').each(function () {
                        let $this = $(this);
                        let storeDistance = Math.round(
                            getDistance(placeLocation, {
                                lat: parseFloat($this.data('lat')),
                                lng: parseFloat($this.data('lng')),
                            }),
                        );
                        if (storeDistance > menaObject.zipCodeDistanceAlternate) {
                            $this.addClass('hide');
                        } else {
                            $this.removeClass('hide');
                            markersInAreaCount++;
                            hasStoreInFirstRadius = true;
                            let storeData = {
                                id      : $this.attr('id'),
                                distance: storeDistance,
                            };

                            activeStores.push(storeData);
                        }
                    });
                }

                $('.store-locator__label').html(`<strong>${markersInAreaCount}</strong> ${label} ${placeAddress}`);

                if (activeStores.length > 0) {
                    activeStores.sort(function (a, b) {
                        return a.distance - b.distance;
                    });

                    activeStores.forEach((item) => {
                        $('#' + item.id).css('order', item.distance);
                    });
                } else {
                    console.log('No stores found in the area');
                }
            }

            prepareData(placeLocation);

            /** TODO */
            let prefooterEventData = localStorage.getItem('trackingPrefooter');
            if (prefooterEventData) {
                let searchResultsCount = document.querySelectorAll('.store-locator__item:not(.hide)').length;

                let trackingData = {
                    event: 'search-query',
                    info : {
                        searchType          : 'filter',
                        searchKeyword       : prefooterEventData,
                        searchResult        : '',
                        searchResultPosition: 1,
                        searchResultCount   : searchResultsCount,
                    },
                };

                storeLocatorAtrack.trackEvent(trackingData);
                localStorage.removeItem('trackingPrefooter');
            }

            localStorage.removeItem('zipLocation');
        } else {
            prepareData({
                lat: 0,
                lng: 0,
            });
        }

        //Toggle navigation
        $('.store-locator__nav-item').on('click', function (ev) {
            ev.preventDefault();
            if (!$(this).hasClass('active')) {
                $('.store-locator__nav-item.active').removeClass('active');
                $(this).addClass('active');
                let item = $(this).data('nav');
                if (item === 'map') {
                    $('.store-locator__locations').fadeOut('fast', function () {
                        $('.store-locator__map').fadeIn('fast');
                    });
                } else {
                    $('.store-locator__map').fadeOut('fast', function () {
                        $('.store-locator__locations').fadeIn('fast');
                    });
                }
            }
        });

        // Track body shop click after search.
        $('.store-locator').on('click', '.store-locator__item', function () {
            if (bodyShopSearchDone && !$(this).hasClass('hide')) {
                let bodyShopCount = $('.store-locator__locations .store-locator__item:not(.hide)').length;
                let bodyShopSearchQuery = $('.store-locator__search input[type="text"]').prop('value');
                let bodyShopPosition = $(this).data('position');
                let bodyShopItem = $(this).find('.store-locator__item-title').text().replace(/\s/gm, '');
                let trackingEvent = {
                    event: 'search-resultclick',
                    info : {
                        searchType          : 'filter',
                        searchKeyword       : String(bodyShopSearchQuery),
                        searchResult        : bodyShopItem,
                        searchResultPosition: String(bodyShopPosition),
                        searchResultCount   : String(bodyShopCount),
                    },
                };

                storeLocatorAtrack.trackEvent(trackingEvent);
            }
        });
    }
}

export default function lazyLoadMap() {
	let isInitialized = false;

	// This function initializes the map and autocomplete.
	function initMap() {
		if (isInitialized) return;
		isInitialized = true;

		// Remove event listeners to prevent multiple initializations.
		if (mapInput) {
			mapInput.removeEventListener('focus', initMap);
			mapInput.removeEventListener('click', initMap);
		}
		if (container) {
			container.removeEventListener('click', initMap);
		}

		if ($('.js-map-input').length > 0) {
			const options = {
				componentRestrictions: { country: 'be' },
				fields: ['address_components', 'geometry'],
			};
			const searchBoxInput = document.querySelector('.js-map-input');
			const searchBox = new google.maps.places.Autocomplete(searchBoxInput, options);

			const language = $('html').attr('lang');
			const languageShort = language.substring(0, 2);

			const map = new google.maps.Map(document.querySelector('.prefooter__map'), {
				zoom: 5,
				center: { lat: 0, lng: 0 },
				zoomControl: true,
				language: languageShort,
			});

			// Prevent form submission on Enter key.
			searchBoxInput.addEventListener('keydown', function (event) {
				if (event.keyCode === 13) {
					event.preventDefault();
				}
			});

			// Update autocomplete bounds based on the map's viewport.
			map.addListener('bounds_changed', () => {
				searchBox.setBounds(map.getBounds());
			});

			// Enhance keyboard behavior for autocomplete suggestions.
			const selectFirstOnEnter = function (input) {
				const _addEventListener = input.addEventListener ? input.addEventListener : input.attachEvent;
				function addEventListenerWrapper(type, listener) {
					if (type === 'keydown') {
						const origListener = listener;
						listener = function (event) {
							const suggestionSelected = $('.pac-item-selected').length > 0;
							if (event.which === 13 && !suggestionSelected) {
								const simulatedDownArrow = $.Event('keydown', { keyCode: 40, which: 40 });
								origListener.apply(input, [simulatedDownArrow]);
							}
							origListener.apply(input, [event]);
						};
					}
					_addEventListener.apply(input, [type, listener]);
				}
				if (input.addEventListener) {
					input.addEventListener = addEventListenerWrapper;
				} else if (input.attachEvent) {
					input.attachEvent = addEventListenerWrapper;
				}
			};

			selectFirstOnEnter(searchBoxInput);

			// Handle place selection.
			searchBox.addListener('place_changed', () => {
				const place = searchBox.getPlace();
				const trackingData = place.address_components[1].long_name + ' ' + place.address_components[0].long_name + ' ' + place.address_components[2].long_name + ' ' + place.address_components[3].long_name;

				if (!place || place.length === 0) {
					return;
				}
				localStorage.setItem('zipLocation', JSON.stringify(place));
				localStorage.setItem('trackingPrefooter', trackingData);

				window.location = menaObject.storeLocatorPage + '?display=1';
			});
		}
	}

	// Elements that trigger initialization.
	const mapInput = document.querySelector('.js-map-input');
	const container = document.querySelector('.prefooter');

	if (mapInput) {
		mapInput.addEventListener('focus', initMap);
		mapInput.addEventListener('click', initMap);
	}
	if (container) {
		container.addEventListener('click', initMap);
	}
}

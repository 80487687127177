import contentSlider from '../blocks/swiper/content-slider';
import infoCards from '../blocks/swiper/info-cards';
import logos from '../blocks/swiper/logos';
import repairSlider from '../blocks/swiper/repair-slider';
import services from '../blocks/swiper/services';
import testimonial from '../blocks/swiper/testimonial';
import typesOfRepair from '../blocks/swiper/types-of-repair';
import tabedCard from '../blocks/tabed-card';
import news from '../blocks/swiper/news';

export default function () {
    if ($('.js-types-of-repair').length > 0 || $('.js-services').length > 0 || $('.js-before-after').length > 0 || $('.js-logo-slider').length > 0 || $('.js-testimonial-slider').length > 0 || $('.js-info-cards').length > 0 || $('.js-tabed-cards').length > 0 || $('.js-content-slider-swiper').length > 0 || $('.js-repair-slider').length > 0 || $('.js-news-cards').length > 0) {
        loadjs([menaObject.assets + '/css/swiper.css', menaObject.assets + '/js/swiper.min.js'], function () {
            typesOfRepair();
            logos();
            repairSlider();
            testimonial();
            services();
            infoCards();
            contentSlider();
            news();

            let firstSwiper = $('.tabed-cards__accordion-item.active').find('.js-tabed-cards');
            tabedCard(firstSwiper);
        });
    }
}

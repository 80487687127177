export default function () {
    const swiper = new Swiper('.js-news-cards', {
        slidesPerView : 'auto',
        centeredSlides: true,
        spaceBetween  : 16,
        grabCursor    : false,
        scrollbar     : {
            el: '.news .swiper-scrollbar',
        },
        breakpoints   : {
            770 : {
                allowTouchMove: false,
            },
            1024: {
                centeredSlides: false,
                spaceBetween  : 32,
            },
        },
        navigation    : {
            nextEl: '.news .info-cards-next',
            prevEl: '.news .info-cards-prev',
        },
    });
}

export default function () {
    if ($('.js-map').length > 0) {
        let shopLat = $('.js-map').data('lat');
        let shopLng = $('.js-map').data('lng');
        let shopLocation = { lat: shopLat, lng: shopLng };

        var mapStyles = [
            {
                featureType: 'administrative',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#444444',
                    },
                ],
            },
            {
                featureType: 'landscape',
                elementType: 'all',
                stylers: [
                    {
                        color: '#f2f2f2',
                    },
                ],
            },
            {
                featureType: 'poi',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'poi.business',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'all',
                stylers: [
                    {
                        saturation: -100,
                    },
                    {
                        lightness: 45,
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'simplified',
                    },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.icon',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'transit',
                elementType: 'all',
                stylers: [
                    {
                        visibility: 'off',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'all',
                stylers: [
                    {
                        color: '#b4d4e1',
                    },
                    {
                        visibility: 'on',
                    },
                ],
            },
        ];

        let language = $('html').attr('lang');
		let languageShort = language.substring(0, 2);

        const map = new google.maps.Map(document.querySelector('.js-map'), {
            zoom: 9,
            center: shopLocation,
            styles: mapStyles,
            disableDefaultUI: true,
            zoomControl: true,
            language: languageShort,
        });

        const marker = new google.maps.Marker({
            position: shopLocation,
            map: map,
            icon: menaObject.pin,
        });
    }
}

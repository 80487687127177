export default function () {
    if ($('section.quote-tool').length) {
        loadjs([menaObject.assets + '/css/select2.min.css', menaObject.assets + '/css/quote-tool.css', menaObject.assets + '/js/jquery.qrcode.min.js', menaObject.assets + '/js/select2.min.js'], function () {
            loadjs([menaObject.assets + '/js/quote-tool.js'], function () {
                $('section.quote-tool > .loader').fadeOut();

                setTimeout(() => {
                    $('section.quote-tool > .l-wrapper').animate(
                        {
                            height: 'toggle',
                            opacity: 'toggle',
                        },
                        500
                    );

                    setTimeout(() => {
                        if ($('section.quote-tool').length) {
                            $([document.documentElement, document.body]).animate(
                                {
                                    scrollTop: $('section.quote-tool').offset().top - 132,
                                },
                                200
                            );
                        }
                    }, 300);
                }, 300);
            });
        });
    }
}

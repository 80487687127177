export default function () {
    const swiper = new Swiper('.js-services', {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 16,
        grabCursor: true,
        breakpoints: {
            1024: {
                centeredSlides: false,
                slidesPerView: 3,
            },
        },
        navigation: {
            nextEl: '.services-next',
            prevEl: '.services-prev',
        },
    });
}

export default function () {
	if ($('.js-custom-body-shop-select').length > 0) {
		$('.js-custom-body-shop-select select').on('change', function () {
			const selected = $(this).find('option:selected').val();
			if(selected) {
				$('.js-send-to-email input').val(selected);
			}
		});
	}
}

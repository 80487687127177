export default function tabedCards(element) {
    $('.tabed-cards__navigation-item').on('click', function (ev) {
        ev.preventDefault();

        if (!$(this).hasClass('active')) {
            $('.tabed-cards__navigation-item.active').removeClass('active');
            $(this).addClass('active');
            let tabId = $(this).data('tab-id');
            let openedItem = $(this).closest('.tabed-cards__navigation').siblings('.tabed-cards__accordion').find('.tabed-cards__accordion-item.active');

            let nextItem = $(this)
                .closest('.tabed-cards__navigation')
                .siblings('.tabed-cards__accordion')
                .find('.tabed-cards__tab[data-tab="' + tabId + '"]')
                .parent('.tabed-cards__accordion-item');

            $(openedItem).fadeOut('fast', function () {
                $(this).removeClass('active');
                $(nextItem).fadeIn().addClass('active');
                let tempSwiper = $(nextItem).find('.js-tabed-cards');

                if (tempSwiper) {
                    tabedCards(tempSwiper);
                }
            });
        }
    });

    $('.tabed-cards__accordion-title').on('click', function (ev) {
        ev.preventDefault();

        if (!$(this).hasClass('active')) {
            $('.tabed-cards__accordion-title.active').removeClass('active');
            $(this).addClass('active');
            let openedItem = $(this).closest('.tabed-cards__accordion').find('.tabed-cards__tab.active');

            let nextItem = $(this).siblings('.tabed-cards__tab');

            $(openedItem).slideToggle('fast', function () {
                $(this).removeClass('active');
                $(nextItem).slideToggle().addClass('active');
                let tempSwiper = $(nextItem).find('.js-tabed-cards');

                if (tempSwiper) {
                    tabedCards(tempSwiper);
                }
            });
        }
    });

    if(element) {
        const swiper = new Swiper($(element)[0], {
            slidesPerView: 'auto',
            spaceBetween: 16,
            updateOnWindowResize: true,
            pagination: {
                el: '.swiper-pagination',
            },
            breakpoints: {
                1024: {
                    spaceBetween: 32,
                },
            },
            navigation: {
                nextEl: '.info-cards-next',
                prevEl: '.info-cards-prev',
            },
        });
    }
}
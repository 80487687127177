export default function () {
    $('.tabs__navigation-item').on('click', function (ev) {
        ev.preventDefault();

        if (!$(this).hasClass('active')) {
            $('.tabs__navigation-item.active').removeClass('active');
            $(this).addClass('active');
            let tabId = $(this).data('tab-id');
            let openedItem = $(this).closest('.tabs__navigation').siblings('.tabs__accordion').find('.tabs__accordion-item.active');

            let nextItem = $(this)
                .closest('.tabs__navigation')
                .siblings('.tabs__accordion')
                .find('.tabs__tab[data-tab="' + tabId + '"]')
                .parent('.tabs__accordion-item');

            $(openedItem).fadeOut('fast', function () {
                $(this).removeClass('active');
                $(nextItem).fadeIn().addClass('active');
            });
        }
    });
    $('.tabs__accordion-title').on('click', function (ev) {
        ev.preventDefault();

        if (!$(this).hasClass('active')) {
            $('.tabs__accordion-title.active').removeClass('active');
            $(this).addClass('active');
            let openedItem = $(this).closest('.tabs__accordion').find('.tabs__tab.active');

            let nextItem = $(this).siblings('.tabs__tab');

            $(openedItem).slideToggle('fast', function () {
                $(this).removeClass('active');
                $(nextItem).slideToggle().addClass('active');
            });
        }
    });
}

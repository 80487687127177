export default function () {
    var formStartedFilling = false;
    var newsletterStartedFilling = false;
    var atrack = analyticstracker();

    let trackContactFormComplete;
    let trackContactFormError;

    let previousTrackingObject = sessionStorage.getItem('trackingObject');
    let currentTrackingObject = $('body').data('tracking-info');

    let initialReferrer = sessionStorage.getItem('initialReferrer');

    if (!initialReferrer) {
        sessionStorage.setItem('initialReferrer', document.referrer);
        initialReferrer = document.referrer;
    }

    if (previousTrackingObject) {
        previousTrackingObject = JSON.parse(previousTrackingObject);
        let previousPageObject = String(previousTrackingObject.pageUrl).split('?')[0];
        let previousPage = String(document.referrer).split('?')[0];
        let currentPage = String(window.location.href).split('?')[0];

        if (currentPage != previousPage && previousPage == previousPageObject) {
            currentTrackingObject.previousPageFullUrl = previousTrackingObject.pageFullUrl;
            currentTrackingObject.previousPageName = previousTrackingObject.pageName;
            currentTrackingObject.previousPageType = previousTrackingObject.pageType;
            currentTrackingObject.previousPageUrl = previousTrackingObject.pageUrl;
            currentTrackingObject.previousPlatform = previousTrackingObject.platform;
            currentTrackingObject.previousBrand = previousTrackingObject.brand;
            currentTrackingObject.previousDealerID = previousTrackingObject.dealerID;
            currentTrackingObject.previousPageViewMode = previousTrackingObject.pageviewMode;
            currentTrackingObject.initialReferrer = initialReferrer;

            $('body').attr('data-tracking-info', JSON.stringify(currentTrackingObject));
        }

        sessionStorage.setItem('trackingObject', JSON.stringify($('body').data('tracking-info')));
    } else {
        currentTrackingObject.initialReferrer = initialReferrer;

        $('body').attr('data-tracking-info', JSON.stringify(currentTrackingObject));

        sessionStorage.setItem('trackingObject', JSON.stringify($('body').data('tracking-info')));
    }

    atrack.trackImpression('page-impression');

    /**
     * Click tracking
     */

    $('[data-tracking-event$=-click]').click(atrack.trackInteraction);

    /**
     * Content block impressions
     */

    function trackContentBlockImpression() {
        atrack.trackElement($('[data-tracking-event=content-block-impression]'));
    }
    setInterval(function () {
        trackContentBlockImpression();
    }, 1000);

    /**
     * Body shop & global contact form tracking
     */

    $('section.form .gravity-form-container input[type="text"], section.form .gravity-form-container select, section.form .gravity-form-container input[type="checkbox"]').on('change', function () {
        if (!formStartedFilling) {
            let trackingEvent = {
                event: 'tool-start',
                info: {
                    toolType: 'contact-request',
                    toolName: 'contact-form',
                    toolStep: 'personal-info',
                    leadID: '',
                    requestType: 'contact-dealer',
                    toolStepOption: '',
                },
            };

            atrack.trackEvent(trackingEvent);
            formStartedFilling = true;
        }
    });

    $('section.form .gravity-form-container, .single-body-shop .gravity-form-container').on('click', 'input[type="submit"]', function () {
        $('div.validation_error').remove();

        let contactFormSubmitErrorFlag = false;
        let contactFormSubmitCompleteFlag = false;

        let subject = $('.js-form-subject').find('select').prop('value');

        let trackingEvent = {
            event: 'tool-submit',
            info: {
                toolType: 'contact-request',
                toolName: 'contact-form',
                toolStep: 'personal-info',
                leadID: '',
                requestType: 'contact-dealer',
                toolStepOption: subject,
            },
        };

        atrack.trackEvent(trackingEvent);

        clearInterval(trackContactFormError);

        trackContactFormError = setInterval(function () {
            if ($('div.validation_error').length > 0 && !contactFormSubmitErrorFlag) {
                let errorMessage = $('.gform_validation_errors .validation_error p').text();

                contactFormSubmitErrorFlag = true;

                let trackingEvent = {
                    event: 'tool-error',
                    info: {
                        toolType: 'contact-request',
                        toolName: 'contact-form',
                        toolStep: 'personal-info',
                        leadID: '',
                        requestType: 'contact-dealer',
                        toolStepOption: '',
                        errorType: 'user-error',
                        errorMessage: errorMessage,
                    },
                };

                atrack.trackEvent(trackingEvent);
                clearInterval(trackContactFormError);
            }
        }, 2000);

        clearInterval(trackContactFormComplete);

        trackContactFormComplete = setInterval(function () {
            if ($('.gform_confirmation_message').length > 0 && !contactFormSubmitCompleteFlag) {
                contactFormSubmitCompleteFlag = true;
                clearInterval(trackContactFormComplete);

                let trackingEvent = {
                    event: 'tool-complete',
                    info: {
                        toolType: 'contact-request',
                        toolName: 'contact-form',
                        toolStep: 'thank-you',
                        leadID: '',
                        requestType: 'contact-dealer',
                        toolStepOption: '',
                    },
                };

                atrack.trackEvent(trackingEvent);
            }
        }, 3000);
    });

    if($('section.form .gravity-form-container').length > 0 || $('.single-body-shop .gravity-form-container').length > 0) {

        let contactFormSubmitCompleteFlag = false;

        trackContactFormComplete = setInterval(function () {
            if ($('.gform_confirmation_message').length > 0 && !contactFormSubmitCompleteFlag) {
                contactFormSubmitCompleteFlag = true;
                clearInterval(trackContactFormComplete);

                let trackingEvent = {
                    event: 'tool-complete',
                    info: {
                        toolType: 'contact-request',
                        toolName: 'contact-form',
                        toolStep: 'thank-you',
                        leadID: '',
                        requestType: 'contact-dealer',
                        toolStepOption: '',
                    },
                };

                atrack.trackEvent(trackingEvent);
            }
        }, 3000);
    };

    /**
     * Newsletter form tracking
     */
    $('#mc_embed_signup input.email').on('input', function () {
        if (!newsletterStartedFilling) {
            let trackingEvent = {
                event: 'tool-start',
                info: {
                    toolType: 'request-form',
                    toolName: 'newsletter-subscription',
                    toolStep: 'personal-info',
                    leadID: '',
                    requestType: 'newsletter',
                    toolStepOption: '',
                },
            };

            atrack.trackEvent(trackingEvent);
            newsletterStartedFilling = true;
        }
    });

    let trackSubscribeSubmitSuccessFlag = false;
    let trackErrorFlag = false;

    $('#mc_embed_signup #mc-embedded-subscribe').on('click', function () {
        let trackingEvent = {
            event: 'tool-submit',
            info: {
                toolType: 'request-form',
                toolName: 'newsletter-subscription',
                toolStep: 'personal-info',
                leadID: '',
                requestType: 'newsletter',
                toolStepOption: '',
            },
        };

        atrack.trackEvent(trackingEvent);

        // Let's listen for successfull submission and clear interval once it happens
        let trackSubscribeSubmitSuccess = setInterval(checkForSuccess, 2000);

        // Get successfull submission status and if it's not hidden, define tracking event, submit and stop interval
        function checkForSuccess() {
            let successContainer = document.querySelector('#mce-success-response');

            if (successContainer.style.display != 'none' && !trackSubscribeSubmitSuccessFlag) {
                trackSubscribeSubmitSuccessFlag = true;

                let trackingEventSubscrimeComplete = {
                    event: 'tool-complete',
                    info: {
                        toolType: 'request-form',
                        toolName: 'newsletter-subscription',
                        toolStep: 'thank-you',
                        leadID: '',
                        requestType: 'newsletter',
                        toolStepOption: '',
                    },
                };

                atrack.trackEvent(trackingEventSubscrimeComplete);
                clearInterval(trackSubscribeSubmitSuccess);
            }
        }

        // Let's listen for error on submission and clear interval once it happens
        let trackErrorSubscribeSubmit = setInterval(checkForError, 2000);

        // Get error submission status and if it's not hidden, define tracking event and stop interval
        function checkForError() {
            let errorContainer = document.querySelector('#mce-error-response');

            if (errorContainer.style.display != 'none' && !trackErrorFlag) {
                let errorMessage = $('#mce-error-response').text();

                trackErrorFlag = true;

                let trackingEventSubscribeError = {
                    event: 'tool-error',
                    info: {
                        toolType: 'request-form',
                        toolName: 'newsletter-subscription',
                        toolStep: 'personal-info',
                        leadID: '',
                        requestType: 'newsletter',
                        toolStepOption: '',
                        errorType: 'user-error',
                        errorMessage: errorMessage,
                    },
                };

                atrack.trackEvent(trackingEventSubscribeError);
                clearInterval(trackErrorSubscribeSubmit);
            }
        }
    });

    /**
     * Yoast FAQ tracking.
     */

    $('.wp-block-yoast-faq-block .schema-faq-section').each(function (index, element) {
        $(this).find('.schema-faq-answer').attr('data-tracking-event', 'content-block-impression');

        let trackingData = JSON.stringify({
            containerName: String('faq-' + $(this).find('.schema-faq-question').text().toLowerCase().replace(/\s+/g, '-').replace(',', '')),
            containerPosition: String(index + 1),
            containerVersion: '',
        });

        $(this).find('.schema-faq-answer').attr('data-tracking-info', trackingData);
    });

    /**
     * Quote tool tracking
     */

    let step1StartFlag = false;

    $('.c-step--1 input').on('input change', function () {
        if (!step1StartFlag) {
            step1StartFlag = true;

            let trackingEvent = {
                event: 'tool-start',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'personal-info',
                    toolStepNumber: '1',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: '',
                },
            };

            atrack.trackEvent(trackingEvent);
        }
    });

    let step2StartFlag = false;

    $('.c-step--2 input, .c-step--2 select').on('input change', function () {
        if (!step2StartFlag) {
            step2StartFlag = true;

            let trackingEvent = {
                event: 'tool-start',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'vehicle-info',
                    toolStepNumber: '2',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: '',
                },
            };

            atrack.trackEvent(trackingEvent);
        }
    });

    let step2aStartFlag = false;

    $('.c-step--2a').on('click', '.clickable-path', function () {
        if (!step2aStartFlag) {
            step2aStartFlag = true;

            let trackingEvent = {
                event: 'tool-start',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'parts-to-repair',
                    toolStepNumber: '2',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: '',
                },
            };

            atrack.trackEvent(trackingEvent);
        }
    });

    let stepImageUploadStartFlag = false;

    $('.c-step--image-upload').on('change input', 'input', function () {
        if (!stepImageUploadStartFlag) {
            stepImageUploadStartFlag = true;

            let trackingEvent = {
                event: 'tool-start',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'damage-picture',
                    toolStepNumber: '2',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: '',
                },
            };

            atrack.trackEvent(trackingEvent);
        }
    });

    $('.c-step--3').on('click', '.store-locator__item', function () {
        let canTrack = $('section.quote-tool').attr('data-carroserie-tracked');

        if (canTrack == 'track') {
            let trackingEvent = {
                event: 'tool-start',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'carrosserie-center',
                    toolStepNumber: '3',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: '',
                },
            };

            atrack.trackEvent(trackingEvent);

            $('section.quote-tool').attr('data-carroserie-tracked', 'tracking-finished');
        }
    });

    $('#js-navigation-right').on('click', function () {
        let submitedStep = $('.c-step.active').data('step-name');

        if (submitedStep == 'step-1') {
            let trackingEvent = {
                event: 'tool-submit',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'personal-info',
                    toolStepNumber: '1',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: '',
                },
            };

            atrack.trackEvent(trackingEvent);
        }

        if (submitedStep == 'step-2') {
            let brand = $('#select2-step-2-brand-container').text();
            let model = $('#select2-step-2-model-container').text();
            let type = $('.c-step--2 .c-image-select__image.active').data('value');

            let trackingEvent = {
                event: 'tool-submit',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'vehicle-info',
                    toolStepNumber: '2',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: String(brand + '|' + model + '|' + type),
                },
            };

            atrack.trackEvent(trackingEvent);
        }

        if (submitedStep == 'step-2a') {
            let selectedParts = '';

            $('.c-step--2a .c-tabs__selection-damage').each(function (index, element) {
                if (index + 1 == $('.c-tabs__selection-damage').length) {
                    selectedParts += $(element).text();
                } else {
                    selectedParts += $(element).text() + ' | ';
                }
            });

            let trackingEvent = {
                event: 'tool-submit',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'parts-to-repair',
                    toolStepNumber: '2',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: selectedParts,
                },
            };

            atrack.trackEvent(trackingEvent);
        }

        if (submitedStep == 'step-3') {
            let selectedDealer = String($('.store-locator__item.active').data('plan-manager-id') + ' | ' + $('.store-locator__item.active').find('.store-locator__item-title').text());

            let trackingEvent = {
                event: 'tool-submit',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'carrosserie-center',
                    toolStepNumber: '3',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: selectedDealer,
                },
            };

            atrack.trackEvent(trackingEvent);
        }

        if (submitedStep == 'step-image-upload') {
            let trackingEvent = {
                event: 'tool-submit',
                info: {
                    toolType: 'request-form',
                    toolName: 'wondercar-quotation',
                    toolStep: 'damage-picture',
                    toolStepNumber: '2',
                    leadID: '',
                    requestType: 'wondercar-quotation',
                    toolStepOption: '',
                },
            };

            atrack.trackEvent(trackingEvent);
        }
    });

    let stepFinishFlag = false;

    setInterval(() => {
        if ($('.c-step--finish').hasClass('active')) {
            if (!stepFinishFlag) {
                stepFinishFlag = true;

                let trackingEvent = {
                    event: 'tool-complete',
                    info: {
                        toolType: 'request-form',
                        toolName: 'wondercar-quotation',
                        toolStep: 'thank-you',
                        toolStepNumber: '4',
                        leadID: '',
                        requestType: 'wondercar-quotation',
                        toolStepOption: '',
                    },
                };

                atrack.trackEvent(trackingEvent);
            }
        }
    }, 1000);

    $('.footer__contact-text a[href^="tel:"]').on('click', function (event) {
		let text = $(this).text();
		let url = $(this).attr('href');

		let trackingEvent = {
			event: 'button-click',
			info: {
				buttonType: 'link',
				buttonCategory: 'interaction',
				buttonName: 'to-call',
				buttonText: text,
				buttonTarget: url,
				buttonPosition: 'footer',
			},
		};

		if (typeof atrack !== 'undefined' && typeof atrack.trackEvent === 'function') {
			atrack.trackEvent(trackingEvent);
		} else {
			console.error('atrack.trackEvent is not defined');
		}
	});

    $('.contact a[href^="tel:"]').on('click', function (event) {
		let text = $(this).text();
		let url = $(this).attr('href');

		let trackingEvent = {
			event: 'button-click',
			info: {
				buttonType: 'link',
				buttonCategory: 'interaction',
				buttonName: 'to-call',
				buttonText: text,
				buttonTarget: url,
				buttonPosition: 'contact',
			},
		};

		if (typeof atrack !== 'undefined' && typeof atrack.trackEvent === 'function') {
			atrack.trackEvent(trackingEvent);
		} else {
			console.error('atrack.trackEvent is not defined');
		}
	});
}

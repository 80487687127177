export default function () {
    $('.js-content-slider-swiper').each(function (index, element) {
        const swiper = new Swiper($(element)[0], {
            scrollbar: {
                el: '.js-content-slider-swiper-scrollbar',
                hide: false,
                draggable: true,
                snapOnRelease: true,
            },
            grabCursor: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                0: {
                    spaceBetween: 8,
                },
                768: {
                    spaceBetween: 16,
                },
            },
        });
    });
}

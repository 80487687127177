export default function () {
    const swiper = new Swiper('.js-info-cards', {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 16,
        grabCursor: true,
        pagination: {
            el: '.swiper-pagination',
        },
        breakpoints: {
            1024: {
                centeredSlides: false,
                spaceBetween: 32,
            },
        },
        navigation: {
            nextEl: '.info-cards-next',
            prevEl: '.info-cards-prev',
        },
    });
}

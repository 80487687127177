export default function () {
    const swiper = new Swiper('.js-repair-slider', {
        slidesPerView: 1,
        spaceBetween: 12,
        loop: true,
        grabCursor: true,
        breakpoints: {
            640: {
                slidesPerView: 3,
                spaceBetween: 16,
            },
        },
        navigation: {
            nextEl: '.repair-slider-next',
            prevEl: '.repair-slider-prev',
        },
    });
}

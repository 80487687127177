export default function () {
    var respMenu = $('.resp-menu');
    var button = $('.js-menu-toggle');
    var body = $('body');

    button.click(function (e) {
        body.toggleClass('menu-open');
        button.toggleClass('menu-toggle--active');
        $('.resp-menu .mega-menu-item.active').removeClass('active');
        $('.resp-menu .mega-menu__repairs-list.active').removeClass('active');
        $('.resp-menu-wrap.move-left').removeClass('move-left');
        $('.resp-menu .mega-menu--mobile').removeClass('active');
    });

    /* Sliding side menu submenu */
    respMenu.find('.menu-item > a').click(function (e) {
        if ($(this).attr('href') == '#') {
            e.preventDefault();
            $('.sub-menu').not($(this).siblings()).slideUp(300);
            $(this).siblings('.sub-menu').slideToggle(300);
        }
    });

    /* Adding submenu arrows to the responsive navigation */
    respMenu.find('.sub-menu').each(function () {
        $(this).parent().append('<a class="submenu-button" href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="10.062" height="6.445"><g data-name="Group 13103"><path data-name="Path 6148" d="M9.355.707L5.031 5.031.707.707" fill="none" stroke="#42AFAF" stroke-width="2"/></g></svg></a>');
    });

    $('.menu--footer-menu')
        .find('.sub-menu')
        .each(function () {
            $(this).parent().append('<a class="submenu-button" href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="10.062" height="6.445"><g data-name="Group 13103"><path data-name="Path 6148" d="M9.355.707L5.031 5.031.707.707" fill="none" stroke="#42AFAF" stroke-width="2"/></g></svg></a>');
        });

    $('.submenu-button').click(function () {
        $('.sub-menu').not($(this).siblings()).slideUp(300);
        $(this).prev('.sub-menu').slideToggle(100);
        $(this).toggleClass('rotate-arrow');
    });
}

import stickyHeader from './general/sticky-header';
import loadAssets from './general/load-assets';
import mobileMenu from './components/mobile-menu';
import footer from './components/footer';
import tabs from './blocks/tabs';
import tabedCard from './blocks/tabed-card';
import accordions from './blocks/accordions';
import storeLocator from './blocks/store-locator';
import repairs from './blocks/repairs';
import authorNavigation from './components/author-navigation';
import megaMenu from './components/mega-menu';
import megaMenuMobile from './components/mega-menu-mobile';
import singleMap from './components/single-map';
import prefooter from './components/prefooter';
import contest from './blocks/contest';
import jobContact from './blocks/job-contact';
import archiveJobContact from './blocks/archive-job-contact';
import quoteTool from './quote-tool';
import tracking from './general/tracking';
import customFileUpload from './components/custom-file-upload';
import dynamicContentSelect from './components/dynamic-contact-select';
import archiveNews from './blocks/swiper/archiveNews';

var mena = window.mena || {};

document.addEventListener('DOMContentLoaded', function (event) {
	stickyHeader();
	loadAssets();
	mobileMenu();
	svg4everybody();
	footer();
	tabs();
	tabedCard();
	accordions();
	storeLocator();
	repairs();
	authorNavigation();
	megaMenu();
	megaMenuMobile();
	singleMap();
	prefooter();
	contest();
	tracking();
	quoteTool();
	jobContact();
	archiveJobContact();
	customFileUpload();
	dynamicContentSelect();

	if ($('.archive-news')) {
		archiveNews();
	}
	// gravityFormEvents();
});

export default function () {
    $('.author-navigation__items--item').on('click', function (event) {
        event.preventDefault();

        const targetId = $(this).data('id');
        const targetElement = $('#' + targetId);

        if (targetElement.length) {
            $('html, body').animate(
                {
                    scrollTop: targetElement.offset().top - 96,
                },
                1000
            );
        } else {
            console.error('Element with ID ' + targetId + ' does not exist.');
        }
    });
}

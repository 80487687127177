export default function () {
    let fileName = '';

    $('.custom-file-upload .gfield_description').on('click', function () {
        $(this).siblings('.ginput_container').find('input[type="file"]').trigger('click');
    });

    $('.custom-file-upload input[type="file"]').on('change', function () {
        fileName = $(this)
            .val()
            .substr($(this).val().lastIndexOf('\\') + 1);
        $('.custom-file-upload .gfield_description').text(fileName);
    });
}

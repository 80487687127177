export default function () {
    let pageNumber, category;
    let repairsAtrack = analyticstracker();
    let repairSearchDone = false;
    let url = location.href.replace(/\/$/, '');

    refreshImpressions();

    if (location.hash) {
        const hash = url.split('#');
        let categoryName = hash[1];

        let filter = $('[data-slug="' + categoryName + '"]');
        $('.all-repairs__filter.active').removeClass('active');
        $(filter).addClass('active');

        $('.repair:not([data-type-name="' + categoryName + '"])').hide();
        $('.repair[data-type-name="' + categoryName + '"]').fadeIn();

        refreshImpressions();
    }

    $('.all-repairs__nav-toggle').on('click', function () {
        $('.all-repairs__filters').slideToggle();
    });

    $('.all-repairs__filter').on('click', function (ev) {
        if (!$(this).hasClass('active')) {
            $('.all-repairs__filter.active').removeClass('active');
            $(this).addClass('active');
            category = $('.all-repairs__filter.active').data('type');
            if (category == 'all') {
                $('.repair').fadeIn().addClass('active');
            } else {
                $('.repair:not([data-type="' + category + '"])')
                    .hide()
                    .removeClass('active');
                $('.repair[data-type="' + category + '"]')
                    .fadeIn()
                    .addClass('active');
            }
            let content = document.querySelector('.all-repairs__posts');
            content.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });

            refreshImpressions();
        }
    });

    $('.all-repairs .repair.active').on('click', function () {
        if (repairSearchDone) {
            let repairCount = $('.repair.active').toArray().length;
            let repairName = $(this).data('block-name');
            let searchKeyword = $('.intro__search-wrap input[type="text"]').prop('value');
            let repairPosition = $(this).data('position');

            let trackingEvent = {
                event: 'search-resultclick',
                info: {
                    searchType: 'filter',
                    searchKeyword: String(searchKeyword),
                    searchResult: String(repairName),
                    searchResultPosition: String(repairPosition),
                    searchResultCount: String(repairCount),
                },
            };

            repairsAtrack.trackEvent(trackingEvent);
        }
    });

    // Search init
    var searchInput = $('.intro__search-wrap input');
    var typingTimer; //timer identifier
    var doneTypingInterval = 400; //ti

    /* Handling when user starts typing */
    $(searchInput).on('keyup input paste keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
        clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTyping, doneTypingInterval);

        function doneTyping() {
            var val = $(searchInput).val().trim();
            if (val.length > 2 || val.length === 0) {
                //for checking 3 characters
                let category = $('.all-repairs__filter.active').data('type');
                let search = val.toLowerCase();
                let matchesTitle, matchesText;

                let repairCount = 0;
                let searchKeyword = $('.intro__search-wrap input[type="text"]').prop('value');

                $('.repair.active').each(function () {
                    let title = $(this).find('.repair__title').text();
                    title = title.toLowerCase();
                    let text = $(this).find('.repair__excerpt').text();
                    text = text.toLowerCase();

                    matchesTitle = title.search(search);
                    matchesText = text.search(search);

                    if (matchesTitle != -1 || matchesText != -1) {
                        $(this).addClass('active');
                        $(this).fadeIn();

                        repairCount++;

                        $(this).attr('data-position', repairCount);
                    } else {
                        $(this).fadeOut();
                    }
                });

                let trackingEvent = {
                    event: 'search-query',
                    info: {
                        searchType: 'filter',
                        searchKeyword: String(searchKeyword),
                        searchResult: '',
                        searchResultPosition: '',
                        searchResultCount: String(repairCount),
                    },
                };

                repairsAtrack.trackEvent(trackingEvent);
                repairSearchDone = true;

                refreshImpressions();
            }
        }
    });

    function refreshImpressions() {
        $('.repair.active').each(function (index, repair) {
            if ($(repair).data('block-name').length > 0) {
                if (!$(repair).data('tracking-event')) {
                    $(repair).attr('data-tracking-event', 'content-block-impression');
                }

                $(repair).removeAttr('data-tracking-done').removeData('tracking-event');

                let activeFilterName = $('.all-repairs__filter.active').data('prefix');
                var repairName = $(repair).data('block-name');

                if (activeFilterName == 'exterior' || activeFilterName == 'interior' || activeFilterName == 'special') {
                    if (repairName.includes(String(activeFilterName + '-'))) {
                        repairName = repairName.replace(String(activeFilterName + '-'), '');
                    }
                }

                let trackingObject = {
                    containerName: activeFilterName + '-' + repairName,
                    containerPosition: index + 1,
                    containerVersion: '',
                };

                $(repair).attr('data-tracking-info', JSON.stringify(trackingObject));
            }
        });
    }
}

export default function () {
    const swiper = new Swiper('.js-types-of-repair', {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 16,
        grabCursor: true,
        breakpoints: {
            1024: {
                centeredSlides: false,
                slidesPerView: 3,
                allowTouchMove: false,
                grabCursor: false,
            },
        },
        navigation: {
            nextEl: '.types-of-repair-next',
            prevEl: '.types-of-repair-prev',
        },
    });
}

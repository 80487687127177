export default function () {
    let menuWidth = $('.resp-menu-wrap').width();

    $('.js-back-service').on('click', function () {
        $('.mega-menu--mobile.active').removeClass('active');
        $('.resp-menu-wrap.move-left').removeClass('move-left');
    });

    $('.js-back-repairs').on('click', function () {
        $('.mega-menu--mobile.active').removeClass('active');
        $('.resp-menu-wrap.move-left').removeClass('move-left');
    });

    $('.js-back-engagement').on('click', function () {
        $('.mega-menu--mobile.active').removeClass('active');
        $('.resp-menu-wrap.move-left').removeClass('move-left');
    });

    $('.js-back-repairs-list').on('click', function () {
        $('.mega-menu__repairs-list.active').removeClass('active');
    });

    $('.resp-menu-wrap .mega-menu-types').on('click', function (ev) {
        ev.preventDefault();
        $('.resp-menu-wrap').addClass('move-left');
        let title = $(this).children('a').text();
        $('.js-back-service').html(title);
        $('.mega-menu--mobile').addClass('active');
        $('.mega-menu-item.active').removeClass('active');
        $('.mega-menu--mobile .mega-menu-item--type').addClass('active');
    });

    $('.resp-menu-wrap .mega-menu-repairs').on('click', function (ev) {
        $('.resp-menu-wrap').addClass('move-left');
        let title = $(this).children('a').text();
        $('.js-back-repairs').html(title);
        $('.mega-menu--mobile').addClass('active');
        $('.mega-menu-item.active').removeClass('active');
        $('.mega-menu--mobile .mega-menu-item--repairs').addClass('active');
    });

    $('.resp-menu-wrap .mega-menu-engagement').on('click', function (ev) {
        $('.resp-menu-wrap').addClass('move-left');
        let title = $(this).children('a').text();
        $('.js-back-engagement').html(title);
        $('.mega-menu--mobile').addClass('active');
        $('.mega-menu-item.active').removeClass('active');
        $('.mega-menu--mobile .mega-menu-item--engagement').addClass('active');
    });

    $('.resp-menu .filters-item').on('click', function () {
        let title = $(this).text();
        $('.js-back-repairs-list').html(title);
        $('.resp-menu .filters-item.active').removeClass('active');
        $(this).addClass('active');
        $('.resp-menu .mega-menu__repairs-list').addClass('active');
        $('.resp-menu .mega-menu__repairs-list').addClass('active');
        $('.resp-menu .mega-menu__repairs-list a').each(function () {
            let type = $('.resp-menu .filters-item.active').data('type');
            if ($(this).hasClass(type)) {
                $(this).addClass('active');
            } else {
                $(this).removeClass('active');
            }
        });
    });
}

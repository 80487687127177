export default function () {
    $('.header .filters-item').on('click', function (ev) {
        ev.preventDefault();
        if (!$(this).hasClass('active')) {
            $('.filters-item.active').removeClass('active');
            $(this).addClass('active');

            $('.header .mega-menu__repairs-list.active').removeClass('active');
            $('.header .mega-menu__repairs-list a').each(function () {
                let type = $('.filters-item.active').data('type');
                if ($(this).hasClass(type)) {
                    $(this).addClass('active');
                } else {
                    $(this).removeClass('active');
                }
            });
        }
    });

    function closeMegaMenu() {
        $('body').removeClass('mega-menu-open');
        $('.header .mega-menu-item.active').removeClass('active');
        $('.header .mega-menu.active').removeClass('active');
        $('.header .mega-menu-repairs.active').removeClass('active');
        $('.header .mega-menu-types.active').removeClass('active');
        $('.header .mega-menu-engagement.active').removeClass('active');
    }

    function handleMegaMenuItem(cssClass) {
        $('body').addClass('mega-menu-open');
        $('.mega-menu').addClass('active');
        $('.mega-menu-item.active').removeClass('active');
        $(cssClass).addClass('active');
    }

    $('.js-close-mega-menu').on('click', function () {
        closeMegaMenu();
    });

    $('.header .mega-menu-types').on('click', function (ev) {
        ev.preventDefault();
        if ($(this).hasClass('active')) {
            closeMegaMenu();
        } else {
            $(this).addClass('active');
            handleMegaMenuItem('.header .mega-menu .mega-menu-item--type');
        }
    });

    $('.header .mega-menu-repairs').on('click', function (ev) {
        ev.preventDefault();
        if ($(this).hasClass('active')) {
            closeMegaMenu();
        } else {
            $(this).addClass('active');
            handleMegaMenuItem('.header .mega-menu .mega-menu-item--repairs');
        }
    });

    $('.header .mega-menu-engagement').on('click', function (ev) {
        ev.preventDefault();
        if ($(this).hasClass('active')) {
            closeMegaMenu();
        } else {
            $(this).addClass('active');
            handleMegaMenuItem('.header .mega-menu .mega-menu-item--engagement');
        }
    });
}
